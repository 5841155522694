<template>
  <base-section>
    <v-container :class="`${g_bLowerBr ? 'px-5' : ''}`">
      <v-row>
        <v-col
          cols="12"
          lg="12"
          md="12"
          :class="`kda-ts-${g_bLowerBr ? '24' : '28'}pt wt-extrabold ${
            g_bLowerBr && 'text-center'
          }`"
          >{{ m_objContent.strTitle }}
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          lg="8"
          md="8"
          :class="`kda-ts-${g_bLowerBr ? '12' : '24'}pt nunito wt-regular ${
            g_bLowerBr && 'text-center'
          }`"
          >{{ m_objContent.strDesctiption }}
        </v-col>
      </v-row>
    </v-container>
    <v-container :class="`${g_bLowerBr ? 'px-5 my-5' : 'my-10'}`">
      <v-row>
        <v-col cols="12" lg="6" md="6">
          <k-d-a-consultations-form :i-col-breakpoint="12" :str-button-label="m_strBtn" />
        </v-col>
        <v-col v-if="$vuetify.breakpoint.lgAndUp" cols="12" lg="1" md="1" />
        <v-col cols="12" lg="5" md="5" :class="`${g_bLowerBr ? 'mt-8' : ''}`">
          <div class="map-responsive">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.710157722549!2d106.77033131506458!3d-6.169551762179047!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f7117df8300d%3A0xc05953c74ffd0ab3!2sKeDa%20Tech!5e0!3m2!1sen!2sid!4v1675846775779!5m2!1sen!2sid"
              width="100%"
              height="450"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div v-for="(item, id) in m_arrAddress" :key="id">
            <component :is="item.strComponent" :class="item.eType">
              {{ item.strText }}
            </component>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';
export default {
  name: 'KDAConsultForm',

  components: {
    KDAConsultationsForm: () => import('@/components/KDAConsultationsForm')
  },

  mixins: [BaseBreakpoint],

  data: () => ({
    m_objSelectedFile: {},
    m_strApplicantName: '',
    m_strApplicantBusinessSector: '',
    m_strApplicantEmail: '',
    m_strApplicantMessage: '',
    m_bFirstTrySubmit: false
  }),

  computed: {
    m_objContent() {
      return {
        strTitle: this.$vuetify.lang.t('$vuetify.kda.consult.form.title'),
        strDesctiption: this.$vuetify.lang.t('$vuetify.kda.consult.form.desc')
      };
    },
    m_arrAddress(add) {
      return [
        {
          strText: this.$vuetify.lang.t('$vuetify.kda.consult.form.office.name'),
          strComponent: 'h1',
          eType: 'kda-ts-24pt nunito wt-bold mt-8 mt-md-8'
        },
        {
          strText: this.$vuetify.lang.t('$vuetify.kda.consult.form.office.address'),
          strComponent: 'h5',
          eType: `kda-ts-${
            this.$vuetify.breakpoint.smAndDown ? 12 : 16
          }pt nunito wt-medium mt-4 mt-md-4`
        }
      ];
    },
    m_objSubmittedMessage() {
      if (this.g_bCareerApplicationSubmitted && !this.g_bCareerApplicationSuccess) {
        return {
          strTitle: 'Submission Failed!',
          strText: 'Seems like the server is busy. Please try again later.'
        };
      } else {
        return {
          strTitle: 'Thank you for your application',
          strText: 'We will provide further information via email.'
        };
      }
    },
    m_strBtn() {
      return this.$vuetify.lang.t(`$vuetify.kda.consult.form.send`);
    }
  }
};
</script>

<style scoped lang="scss">
@import 'src/styles/variables.scss';

.map-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.map-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
</style>
